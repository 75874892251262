import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AppHeaders } from "@models";
import { Router } from "@angular/router";
import { AuthService, PopupService } from "@services";
import { RoutesApp } from "@enums";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private router: Router,
              private popupService: PopupService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let requests = request;
    let headers: AppHeaders = {};

    // Agregar una lista de URLs que NO deben llevar el token de Firebase
    const excludedUrls = [
      'getTramites_discapacidad',
      'getTRamites_exhumacion',
    ];

    // Si la URL está en la lista de exclusión, NO agregar el token
    if (!excludedUrls.some(url => request.url.includes(url))) {
      const currentUser = this.authService.currentUserValue;
      if (currentUser && currentUser?.access_token) {
        headers = { ...headers, Authorization: `Bearer ${ currentUser?.access_token }` };
      }

      const responseType = request.responseType;
      const isFormData = request.body instanceof FormData;

      if (currentUser && currentUser?.access_token) {
        if (isFormData || responseType === 'blob') {
          requests = request.clone({
            setHeaders: { ...headers },
          })
        } else {
          requests = request.clone({
            setHeaders: {
              ...headers,
              'Content-Type': request.method === 'PATCH' ? 'application/merge-patch+json' : 'application/json'
            }
          });
        }
      }
    }

    return next.handle(requests).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && localStorage.getItem('oneError') !== '1') {
          this.popupService.errorAlert('Debe autenticarse, por favor.', 5000);
          this.authService.cleanAll();
          localStorage.setItem('oneError', '1');
        }
        return throwError(err);
      })
    );
  }
}
