// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  KEY_RECAPTCHA_SITE_WEB: '6Lel3eMmAAAAALkg4o93dldoWIAP50FH4KE-E3ii',

  TRAMITE_SHARED_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',
  TRAMITE_SECURITY_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  TRAMITE_NOTIFICATIONS_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/notifications/api/v1',
  TRAMITE_FILE_MANAGER_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/filemanager/api/v1',
  //OPA35_REQUEST_URI_LOCAL: 'http://localhost:5000/api/',
  //OPA35_REQUEST_URI: 'https://app-sds-certdiscapacidad-dev-eastus.azurewebsites.net/api',
  OPA35_REQUEST_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Certdiscapacidad/api',
  B2C_CONFIG: {
    TENANT_NAME: 'saludcapitalb2c',
    CLIENT_ID: '6dfed1c9-50b8-49e6-8bd6-09d850f5ccba',
    SIGN_UP_SIGN_IN: 'b2c_1_InicioSesionConRegistro'
  },
  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
  VUDTS_URL_LOCAL: 'localhost:4200',
  LINK_SURVEY: 'http://saludcapital.gov.co/Paginas2/Inicio.aspx',
  CONTAINER_NAME: 'certdiscapacidad',
  MAX_FILE_MB: 3
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
