import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, Observable, of } from "rxjs";
import { GeneralDropDownLists, RequestsTable } from "@models";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { addWorkingDaysToDate, getHolidaysByYears } from "@helpers";
import { ProcedureTypes, Rol, StatusRequest } from "@enums";
import { AuthService } from "./auth.service";
import { DocTypesShared, Localities } from "@consts";

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient,
              private _authService: AuthService) {
  }

  /**
   * Obtiene las constantes por grupoId
   * @param codigo
   */
  getConstantsByGroupId(codigo: string): Observable<GeneralDropDownLists[]> {
    return this.http.get<GeneralDropDownLists[]>(`${ environment.OPA35_REQUEST_URI }/Constantes/ByGrupos/${ codigo }`);
  }

  /**
   * Obtiene las EPS registradas
   */
  getEps(): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/EPS`);
  }

  /**
   * Obtiene las IPS registradas
   */
  getIps(): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/IPS`);
  }

  /**
   * Envia la notificación en caso de pertenecer a regimen especial
   * @param data
   */
  sendSpecialRegimeNotification(data: any) {
    return this.http.post(`${ environment.OPA35_REQUEST_URI }/Notificaciones/GenerarNotificacionRegimenEspecial`, data);
  }

  /**
   * Obtiene el ciudadano por tipo y numero de documento
   * @param tipoId
   * @param docNumber
   * @param idCitizenToDiscard ID ciudadano a descartar en la consulta
   */
  getCitizenByDocNumber(tipoId: number, docNumber: any, idCitizenToDiscard?: string): Observable<any> {
    let params = new HttpParams()
      .append('TipoIdentificacionId', `${ tipoId }`)
      .append('Identificacion', `${ docNumber }`);

    if (idCitizenToDiscard != null && idCitizenToDiscard != '') {
      params = params.append('IdCiudadanoDescartado', idCitizenToDiscard);
    }
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Ciudadanos`, { params });
  }

  /**
   * Obtiene el historial de seguimiento de una solicitud
   * @param requestId
   */
  getTrackingByRequestId(requestId): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/SeguimientosAuditorias/${ requestId }`)
      .pipe(
        catchError(() => of([]))
      );
  }

  /**
   * Obtiene las solicitudes para los dashboard
   * @param pageIndex
   * @param pageSize
   * @param typeSearch
   * @param filter
   */
  getRequestsForDashboard(pageIndex: number, pageSize: number, typeSearch?: number, filter?: string) {
    const currentUser = this._authService.currentUserValue;
    const role: string = currentUser.rol;
    const userId: string = currentUser.sub;
    let params = new HttpParams()
      .append('PageIndex', `${ pageIndex }`)
      .append('PageSize', `${ pageSize }`);
    if (typeSearch && filter) {
      params = params.append('TypeSeach', `${ typeSearch }`)
        .append('Search', `${ filter }`)
    }
    const apiUrl = this.getApiUrlForDashboardByRoleId(role, (role == Rol.CitizenOrApplicant || role == Rol.Validator) ? userId : null);
    return this.http.get(apiUrl, { params })
      .pipe(
        map((data: any) => {
          return { data: this.transformRequestsData(data.data), total: data.total };
        }), catchError(() => of({ data: [], total: 100 }))
      )
  }

  /**
   * Obtiene las solicitudes para el dashbard de reportes
   * @param startDate
   * @param endDate
   * @param pageIndex
   * @param pageSize
   * @param typeSearch
   * @param filter
   */
  getRequestsForReportDashboard(startDate: string, endDate: string, pageIndex: number, pageSize: number, typeSearch?: number, filter?: string) {
    let params = new HttpParams()
      .append('fechaIni', `${ startDate }`)
      .append('fechaFin', `${ endDate }`)
      .append('PageIndex', `${ pageIndex }`)
      .append('PageSize', `${ pageSize }`);
    if (typeSearch && filter) {
      params = params.append('TypeSeach', `${ typeSearch }`)
        .append('Search', `${ filter }`)
    }
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Tramites/HistoricoByParams`, { params })
      .pipe(
        map((data: any) => {
          console.log(data);
          return { data: this.transformRequestsData(data.data), total: data.total, pageIndex: data.pageIndex, pageSize: data.pageSize };
        }), catchError(() => of({ data: [], total: 100, pageIndex: 1, pageSize: 10}))
      )
  }

  /**
   * Obtiene el API url para cada dashboard
   * @param role
   * @param userId
   * @private
   */
  private getApiUrlForDashboardByRoleId(role: string, userId?: string): string {
    switch (role) {
      case Rol.CitizenOrApplicant:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaCiudadano/${ userId }`;
      case Rol.Validator:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaValidador/${ userId }`;
      case Rol.Coordinator:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaCoordinador`;
      case Rol.Logistic:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaLogistico`;
      case Rol.Revisor:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaRevisor`;
      case Rol.Director:
        return `${ environment.OPA35_REQUEST_URI }/Tramites/BandejaDirector`;
    }
    return '';
  }

  /**
   * Optiene el tráite por ciudadano
   */
  getApiUrlForDashboardById(userId?: string): Observable<any> {
    const url = `${environment.OPA35_REQUEST_URI}/Tramites/BandejaCiudadano/${userId}`;
    return this.http.get<any>(url);
}

  /**
   * Obtiene el ultimo numero de la solicitud de un ciudadano
   * @param citizenId
   */
  getLastRequestNumberByCitizen(citizenId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', accept: 'text/plain' }),
      responseType: 'text'
    };
    // @ts-ignore
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Tramites/${ citizenId }/NumeroRadicadoUltimaSolicitud`, httpOptions);
  }

  /**
   * Obtiene el detalle de una solicitud
   * @param reqId
   */
  getRequestDetails(reqId: string): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Tramites/${ reqId }`);
  }

  /**
   * Obtiene el detalle de una solicitud por radicado
   * @param idRadicado
   */
  getRequestDetailsByRadicado(idRadicado: string): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Tramites/Radicado/${ idRadicado }`);
  }

  /**
   * Realiza la asignación de un funcionario validador
   * @param data
   */
  assignValidator(data): Observable<any> {
    return this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites/AsignarValidador`, data);
  }

  /**
   * Obtiene el listado de validadores activos/inactivos (IDB2C)
   */
  getValidators(): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Usuarios/UsuariosPorRol/validador`)
      .pipe(
        map((validators: any) => {
          const validatorsOpt = [
            { name: 'Activo(s)', data: [] },
            { name: 'Inactivos(s)', data: [] }
          ];
          for (const user of validators) {
            const activeOrNotKey = user.idB2C ? 0 : 1;
            validatorsOpt[activeOrNotKey].data.push({
              name: user.nombreFuncionario,
              value: user.idB2C,
              disabled: user.idB2C == null
            });
          }
          return validatorsOpt;
        })
      )
  }

  /**
   * Realiza el guardado/actualizacion de una solicitud
   * @param data
   */
  saveRequest(data: any): Observable<any> {
    return (data.idTramite)
      ? this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites`, data)
      : this.http.post(`${ environment.OPA35_REQUEST_URI }/Tramites`, data);
  }

  /**
   * Obtiene el conteo de solicitudes realizada por el ciudadano
   * @param citizenId
   */
  getRequestsCountCreatedByCitizenId(citizenId: string): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Ciudadanos/${ citizenId }/NumeroDeTramites`);
  }

  /**
   * Permite la previsualizacion de una orden de valoracion
   * @param data
   * @param acta
   */
  previewOrderValuation(data, acta: number = 1): Observable<any> {
    const options = { responseType: 'blob' as 'json' }
    if (acta == 1) {
      return this.http.post(`${ environment.OPA35_REQUEST_URI }/Certificados/Previsualizacion/OrdenValoracion`, data, options);
    } else {
      return this.http.post(`${ environment.OPA35_REQUEST_URI }/Certificados/Previsualizacion/OficioActualizacion`, data, options);
    }
  }

  /**
   * Realizar la gestión/actualización de una solicitud dependiendo del rol funcionario
   * @param reqId
   * @param data
   * @param role
   */
  updateRequestBySds(reqId, data, role): Observable<any> {
    if (role == 'validador') {
      return this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites/GestionarValidador`, data);
    } else if (role == 'coordinador') {
      return this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites/GestionarCoordinador`, data);
    } else if (role == 'director') {
      return this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites/GestionarDirector`, data);
    } else {
      return this.http.put(`${ environment.OPA35_REQUEST_URI }/Tramites/GestionarRevisor`, data);
    }
  }

  /**
   * Almacena los documentos guardadods en blobstorage en nuestra BD para tenerlos como referencia
   * @param _files
   */
  saveFilesRelatedToProcedure(_files: any) {
    return this.http.post(`${ environment.OPA35_REQUEST_URI }/Documentos`, _files);
  }

  /**
   * Realizan la actualización de un ciudadano (presencial)
   * @param citizenId
   * @param data
   */
  updateCitizenLocal(citizenId: string, data: any) {
    return this.http.put(`${ environment.OPA35_REQUEST_URI }/Ciudadanos/${ citizenId }`, data);
  }

  /**
   * Permite identificar si un ciudadano tiene solicitudes en proceso.
   * @param citizenId
   */
  verifyRequestInProcessByCitizenId(citizenId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', accept: 'text/plain' }),
      responseType: 'text'
    };
    // @ts-ignore
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Tramites/EnProceso?IdCiudadano=${ citizenId }`, httpOptions)
      .pipe(
        map((data: any) => {
          return { requestInProcess: data };
        }), catchError(() => of({ requestInProcess: null }))
      )
  }

  /**
   * Realiza la adecuación de los datos para las respectivas bandejas de información
   * @param requests
   * @param addSemaforo
   */
  transformRequestsData(requests: any[], addSemaforo: boolean = true): Array<RequestsTable> {
    return requests
      .map(
        request => {
          const semaforoInfo = (addSemaforo) ? this.calculateUsedAttentionTime(request) : null;
          return {
            idTramite: request.idTramite,
            idTipoTramite: request.tipoTramiteId,
            idEstadoTramite: request.estadoTramiteId,
            idRadicado: request.idRadicado,
            nombreSolicitante: request.nombreSolicitante,
            documentoSolicitante: request.numeroDocumento,
            telefono: request.telefono,
            correo: request.correo,
            fechaRegistro: moment(request.fechaRegistroSolicitud).format('DD/MM/YYYY'),
            fechaEfectiva: request.fechaSolicitudEfectiva == '0001-01-01T00:00:00' ? 'Sin Dato' : moment(request.fechaSolicitudEfectiva).format('DD/MM/YYYY'),
            motivo: request.motivoSolicitud,
            estado: request.estadoSolicitud,
            porSubsanarPresencial: (request.estadoTramiteId.toUpperCase() == StatusRequest.SOLICITAR_INFO && request.tipoVentanilla == 'Presencial'),
            porSubsanarVirtual: (request.estadoTramiteId.toUpperCase() == StatusRequest.SOLICITAR_INFO && request.tipoVentanilla == 'Virtual'),
            idValidador: request.usuarioValidadorId ? request.usuarioValidadorId : 'not-value',
            idCiudadanoVentanilla: request.ciudadanoVentanillaId,
            tipoVentanilla: request.tipoVentanilla,
            tipoVentanillaId: request.tipoVentanillaId,
            pathAzureActa: request.pathAzureStorageActa,
            fechaUltimaActualizacion: moment(request.fechaUltimaActualizacion).format('DD/MM/YYYY'),
            descargaActa: request.estadoTramiteId.toUpperCase() == StatusRequest.APROBADO_Y_FIRMADO,
            observaciones: request.observaciones,
            semaforo: {
              label: (addSemaforo) ? `${ semaforoInfo.days } día(s) para gestionar el trámite` : 'No Disponible',
              color: (addSemaforo) ? semaforoInfo.extra.colorTime : 'circulorojo'
            },
            // nueva info
            regimenEspecial: 'NO',
            nombreDispositivoMovilizarse: request.nombreDispositivoMovilizarse,
            otroDispositivoMovilizarse: request.otroDispositivoMovilizarse,
            nombreDispositivoComunicarse: request.nombreDispositivoComunicarse,
            otroDispositivoComunicarse: request.otroDispositivoComunicarse,
            nombreCuidador: request.nombreCuidador,
            documentoCuidador: request.documentoCuidador,
            emailCuidador: request.emailCuidador,
            telefonoCuidador: request.telefonoCuidador,
            celularCuidador: request.celularCuidador,
            diagnostico: request.diagnostico,
            modalidadValoracion: request.modalidadValoracion,
            fuenteFinanciacion: request.fuenteFinanciacion,
            codigoRLCPD: request.codigoRLCPD,
            ipsAtencion: request.ipsAtencion,
            localidadSolicitante: Localities[request.localidad] || 'No Disponible',
            direccionResidencia: request.direccionResidencia,
            celularSolicitante: request.celular,
            tipoIdentificacionDes: DocTypesShared[request.tipoIdentificacion] || 'No Disponible',
            fechaNacimiento: request.fechaNacimiento,
            contrato: request.contrato
            // extra: JSON.stringify({ request, ...semaforoInfo })
          }
        }
      );
  }

  /**
   * Calcula el tiempo de atención restante de la solicitud
   * @param request
   * @private
   */
  private calculateUsedAttentionTime(request: any): { percentUsed: number, days: number, extra?: any } {
    let percentAttentionTime: number = 0;
    let requestDate = moment(request.fechaRegistroSolicitud);
    let daysAdd = (request.estadoTramiteId.toUpperCase() == ProcedureTypes.SEVEN_YEARS || request.estadoTramiteId.toUpperCase() == ProcedureTypes.PERSONAL_INFORMATION) ? 15 : 5;
    const holidays = getHolidaysByYears([ requestDate.year(), requestDate.year() + 1 ]);
    const resultWorkingDays = addWorkingDaysToDate(requestDate.toDate(), daysAdd, holidays);
    let daysDiff: number = resultWorkingDays.businessDaysDiff;
    // si la solicitud la crearon el mismo día
    if (daysDiff == 0) {
      return {
        percentUsed: percentAttentionTime,
        days: daysAdd,
        extra: { colorTime: this.getColorByAttentionTime(percentAttentionTime) }
      };
    }
    daysDiff = daysDiff <= daysAdd ? daysDiff : 0;
    percentAttentionTime = (100 * daysDiff) / daysAdd;
    percentAttentionTime = (percentAttentionTime <= 0) ? 100 : percentAttentionTime;
    const daysUsed = (daysDiff <= 0) ? 0 : daysAdd - daysDiff;
    return {
      percentUsed: Math.floor(percentAttentionTime),
      days: (daysUsed <= 0) ? 0 : daysUsed,
      extra: { colorTime: this.getColorByAttentionTime(Math.floor(percentAttentionTime)) }
    };
  }

  private getColorByAttentionTime(semaforo): string {
    let colorTime = 'circuloverde';
    if (semaforo > 25 && semaforo < 75) colorTime = 'circuloamarillo';
    if (semaforo > 75) colorTime = 'circulorojo';

    return colorTime;
  }

}
